<template>
    <div>
        <h3 class="text-center">La password è stata cambiata correttamente</h3>
        <p class="text-center"><router-link class="btn btn-link btn-lg" to="/login"><b-icon icon="house"/> Accedi</router-link> </p>
    </div>
</template>

<script>

export default {
    name: "reset-password-ok"
}
</script>